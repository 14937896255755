import React from 'react'
import { useRecoilValue } from "recoil"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { Icon, Icons } from "../../components/ui/Icons"
import State from "../../../src/utils/global/recoil.states"

import SEO from "../../components/SEO"

interface State {
    name?: string;
    email?: string;
    subject?: string;
    message?: string
}

const Contact = () => {

    const theme = useRecoilValue(State.theme)

    const [formData, _formData] = React.useState<State>({
        name: "",
        email: "",
        subject: "",
        message: ""
    })

    const encode = (data : any) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const handleSubmit = (event : any) => {
        event.preventDefault()
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
            "form-name": event.target.getAttribute("name"),
            ...formData
            })
        })
        .then(() => {
            alert("Thank you for reaching out. Will get back to you shortly.")
            _formData({
                name: "",
                email: "",
                subject: "",
                message: ""
            })
        })
        .catch(error => {
            alert("Failed. Please Try again!")
        })

    }

    const handleChange = (event : any) => {
        _formData({...formData , [event.target.name]: event.target.value})
    }

    return (
        <>
            <SEO title="Contact" />
            <div className={`fontMontserrat flex justify-center items-center w-full p-1 mt-10 flex-col  md:mt-0 ${theme === "dark" ? "textLight" : "textDark"}`}>
                <div className="flex flex-col p-10  w-full lg:w-1/3">
                    <div className="w-full">
                        <h1 className={`font-light text-3xl md:text-5xl p-1 left-outline pageTitle transform  hover:-translate-y-1 hover:scale-105 duration-500 ${theme === "dark" ? "textLight" : "textDark"}`}> Get in touch </h1>
                        <span className="block mt-5  p-1 rounded-md md:text-xl text-justify">
                            For any feedback or queries you can reach out to me by filling up this form. Thanks for visiting.
                        <br />
                        </span>
                    </div>
                    <form name="contact" onSubmit={handleSubmit} className="flex flex-col w-full mt-5 textDark"  method="POST" data-netlify="true"  data-netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="contact" hidden />
                        <div className="flex flex-row justify-between">
                            <input
                                id="name"
                                type="text"
                                placeholder="Name"
                                name="name"
                                onChange={handleChange}
                                value={formData.name}
                                className="rounded-md p-2 w-1/2 mr-1 shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent "
                            />
                            <input
                                id="email"
                                type="email"
                                placeholder="Email"
                                name="email"
                                onChange={handleChange}
                                value={formData.email}
                                className="rounded-md p-2 w-1/2 ml-1 shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent "
                            />
                        </div>
                        <input
                            id="subject"
                            type="text"
                            placeholder="Subject"
                            name="subject"
                            onChange={handleChange}
                            value={formData.subject}
                            className="block mt-5 rounded-md p-2 shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent "
                        />
                        <textarea
                            id="message"
                            placeholder="Message"
                            name="message"
                            onChange={handleChange}
                            value={formData.message}
                            className="mt-5 rounded-md p-2 shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent "
                        />
                        <div className="flex justify-end">
                            <button
                                disabled={formData.email === ""}
                                type="submit"
                                className="mt-5 bg-blue-500 w-full p-2 rounded-md text-white shadow-2xl"
                            >
                                Submit
                            </button>
                        </div>
                    </form>

                    <span className="block mt-5 p-1 rounded-md md:text-xl text-justify">
                        Or you can also find me on...
                    </span>

                    <div className={`flex flex-row justify-center mt-5 `}>
                        <OutboundLink
                            target="_blank"
                            href="https://www.facebook.com/Sukantash11"
                            className={`text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105 ${theme === "dark" ? "textLight" : "textDark"} duration-500 `}
                        >
                            <Icon icon={Icons.facebookIcon} />
                        </OutboundLink>
                        <OutboundLink
                            target="_blank"
                            href="https://github.com/SukantaB"
                            className={`text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105 bg-white duration-500  `}
                        >
                            <Icon icon={Icons.githubIcon}  />
                        </OutboundLink>
                        <OutboundLink
                            target="_blank"
                            href="https://www.linkedin.com/in/sukanta-saha-64087a55/"
                            className={`text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-10 ${theme === "dark" ? "textLight" : "textDark"} duration-500 `}
                        >
                            <Icon icon={Icons.linkedinIcon} />
                        </OutboundLink>
                        <OutboundLink
                            target="_blank"
                            href="https://twitter.com/sukantsh11"
                            className={`text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105  ${theme === "dark" ? "textLight" : "textDark"} duration-500`}
                        >
                            <Icon icon={Icons.twitterIcon} />
                        </OutboundLink>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact